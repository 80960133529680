import React, { useEffect } from 'react';

const Index = () => {

    return (
        <div>
            <ins class="adsbygoogle"
                style={{ display: "block" }}
                data-ad-format="autorelaxed"
                data-ad-client="ca-pub-6382147321235149"
                data-ad-slot="1033290772"></ins>
        </div>
    );
};

export default Index;