import React, { useEffect } from 'react';

const Index = () => {
   

    return (
        <div>
            <ins class="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6382147321235149"
                data-ad-slot="7174321009"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
        </div>
    );
};

export default Index;